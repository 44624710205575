<template>
<div class="container">
  <div class="head">
    <HeaderView :bgColor="bgColor"></HeaderView>
  </div>
    <div class="banner">
        <div class="banner_text">
            <h1>资讯中心</h1>
            <p>获取行业最新动态</p>
        </div>
    </div>
    <div class="module_title wow animate__fadeInUp" data-wow-duration="2s">
        新闻资讯
        <div class="line"></div>
    </div>
    <div class="auto_box wow animate__fadeInUp" data-wow-duration="2s">
      <ul class="news_box">
      <li v-for="(item,index) in newsList" :key="index" @click="goNewsDetail(item.id)">
        <img :src="baseUrl + item.image" alt="">
        <div class="news_content">
          <h1>{{item.title}}</h1>
          <p>{{item.description}}</p>
          <p><span>{{item.publishtime_text}}</span><span>→</span></p>
        </div>
      </li>
    </ul>
    </div>
    
    <div class="pagination_box wow animate__fadeInUp" data-wow-duration="2s">
      <Pagination @currentChange="currentChange" :total="total" :pagination="pagination"></Pagination>
    </div>
</div>
</template>
<script>
import Pagination from '@/components/pagination.vue'
import { getNewsList } from '@/request/api'
import { baseUrl } from '@/request/baseUrl'
import HeaderView from '@/components/headerView.vue'
baseUrl
export default {
  name: 'news',
  components: { Pagination, HeaderView },
  data(){
    return{
      // bgColor:'rgba(235,229,225,.8)',
      bgColor:'',
      newsList:[],
      pagination:{
        page:1,
        pageSize:9,
      },
      total:0,
    }
  },
  created(){
    this.baseUrl = baseUrl
    this.getNewsList()
  },
  mounted(){
    new this.$wow.WOW().init({//新建实列
      boxClass: 'wow',//class名字
      // animateClass: 'animate__animated',//animateclass动画库类名,和当前animate动画库决定
      offset: 0,//到元素距离触发动画（当默认为0）
      mobile: true,//在移动设备上触发动画（默认为true）
      live: true//对异步加载的内容进行操作（默认为true）
    })
  },
  methods:{
    /**
     * 分页器页码改变后执行的函数
     * @param {Number} pageNum 
     */
      currentChange(pageNum) {
        this.pagination.page = pageNum;
        this.getNewsList()
    },
    goNewsDetail(id){
      this.$router.push({path:'/newsDetail',query:{id:id}})
    },
    getNewsList(){
      getNewsList(this.pagination).then((res)=>{
        this.newsList = res.data.data
        this.total = res.data.total
      })
    }
  }
}
</script>
<style scoped lang="less">
.banner{
    width:1920px;
    height:471px;
    position: relative;
    background: url('../assets/images/newsBanner.jpg')no-repeat;
    background-size:100% 100%;
    .banner_text{
      width:1200px;
      margin:0 auto;
      padding-top:170px;
      padding-left:80px;
      text-align:left;
      color: #000000;
      animation: banner-animation 1s ease-in-out;
    }
    h1{
      height: 40px;
      font-size: 40px;
      margin-bottom:20px;
    }
    p{
      font-size: 30px;
    }
}
.news_box{
  width:1200px;
  margin:0 auto;
  display:flex;
  flex-wrap:wrap;
  li{
    width:380px;
    height:374px;
    margin-bottom:30px;
    margin:0 10px;
    cursor: pointer;
    margin-bottom:40px;
    img{
      width:100%;
      height:214px;
      display:block;
    }
    .news_content{
      border: 1px solid #B5B5B5;
      border-top:none;
      padding-top:30px;
      h1{
        padding:0 15px;
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333333;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      p{
        height: 36px;
        padding:15px;
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #656565;
        line-height: 26px;
        &:nth-of-type(1){
          display: -webkit-box;
          -webkit-box-orient:vertical;
          text-overflow: ellipsis;
          overflow : hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;//表明是2行文本显示省略号，换成3则表明是3行文本显示省略号
          -webkit-box-orient: vertical;
        }
        &:nth-of-type(2){
          display:flex;
          justify-content: space-between;
          align-items:center;
          align-content:center;
          border-top: 1px solid #B5B5B5;
          padding:0;
          margin:15px;
          margin-bottom:0;
          height: 40px;
          span{
            &:nth-of-type(2){
              color:#999999;
              font-size:20px;
            }
          }
        }
      }
    }
  }
}
.pagination_box{
  width:1920px;
  text-align:center;
  padding-bottom:50px;
  box-sizing:border-box;
}
</style>