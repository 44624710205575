<template>
  <div>
    <el-pagination
    @current-change="currentChange"
    :page-sizes="pageSizes"
    :page-size="pagination.pageSize"
    layout="prev, pager, next"
    :total="total">
  </el-pagination>
  </div>
</template>
<script>
export default {
  name: "pagination",
  props: {
    total: {
      type: Number,
      default: 100,
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          page: 1,
          pageSize: 9,
        };
      },
    },
    pageSizes: {
      type: Array,
      default:() => {
        return [9]
      }
    }
  },
  methods: {
    currentChange(pageNum) {
      this.$emit(`currentChange`, pageNum);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-pager {
  font-weight: normal;
  li {
    color:#B5B5B5;
    border: 1px solid #B5B5B5;
    border-radius:50%!important;
    background:#fff!important;
    padding:0!important;
    height:35px;
    line-height:35px;
    margin:0 5px;
  }
  .active {
    background-color: #E7351B !important;
    color:#fff;
    & + li {
      border: 1px solid #B5B5B5 !important;
    }
  }
}
/deep/.btn-prev {
  border: 1px solid #B5B5B5;
  border-radius:50%!important;
  background:#fff!important;
  width:35px;
  height:35px;
  line-height:35px;
  padding:0;
}
/deep/.btn-next {
  border: 1px solid #B5B5B5;
  border-radius:50%!important;
  background:#fff!important;
  width:35px;
  height:35px;
  line-height:35px;
  padding:0;
}
</style>